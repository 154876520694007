import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { RichText } from "prismic-reactjs"
import LayoutTitleParagraphs from "../components/layout-title-paragraphs"
import cn from "classnames"
import { getMainPage } from "../utils/helpers"
import { linkResolver } from "../utils/linkResolver"
import WavesImage from "../components/waves-image"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query TitleParagraphsQuery($uid: String, $lang: String) {
    prismicTitleParagraphs(uid: { eq: $uid }, lang: { eq: $lang }) {
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        title_meta
        meta_description
        title
        subtitle {
          raw
        }
        sides {
          first {
            raw
          }
          who {
            raw
          }
          line {
            raw
          }
          last {
            raw
          }
        }
        items {
          paragraphs {
            raw
          }
          title_term {
            raw
          }
        }
        styles {
          style
        }
      }
    }
  }
`

const RenderBody = ({ sides, items, title, subtitle, styles }) => {
  return (
    <Fragment>
      <section className="bg-dark py-6">
        <div className="container">
          <h1 className="text-white text-center">{title}</h1>
        </div>
      </section>
      <WavesImage page="home" />
      <section className="py-7">
        <div className="container">
          <div className="row justify-content-center">
            <LayoutTitleParagraphs styles={styles}>
              {subtitle?.raw[0] && (
                <div className="my-4">
                  {RichText.render(subtitle.raw, linkResolver)}
                </div>
              )}
              {sides &&
                sides.map((s, i) => (
                  <Fragment key={i}>
                    {s.first?.raw[0] && RichText.render(s.first.raw)}
                    {s.who?.raw[0] && RichText.render(s.who.raw)}
                    {s.line?.raw[0] && RichText.render(s.line.raw)}
                    <div className="text-right">
                      {s.last?.raw[0] && RichText.render(s.last.raw)}
                    </div>
                  </Fragment>
                ))}
              {items.map((item, i) => (
                <div
                  key={i}
                  className={cn({
                    "col-6 col-md-4 mb-4":
                      styles && styles.includes("multiple-columns"),
                    "list-unstyled": styles && styles.includes("list-unstyled"),
                  })}
                >
                  {item.title_term?.raw[0] &&
                    RichText.render(item.title_term.raw, linkResolver)}
                  {item.paragraphs?.raw[0] &&
                    RichText.render(item.paragraphs.raw, linkResolver)}
                </div>
              ))}
            </LayoutTitleParagraphs>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
const TitleParagraphsTemplate = ({ data, pageContext, uri, location }) => {
  const doc = data.prismicTitleParagraphs.data

  if (!doc) return null
  const {
    items,
    title,
    sides,
    subtitle,
    styles,
    title_meta,
    description_meta,
    keywords_meta,
    image_meta,
  } = doc
  const { uid, lang: locale } = pageContext

  const mainPage = getMainPage(uid)
  return (
    <Layout
      metadata={{
        title: title_meta,
        description: description_meta,
        keywords: keywords_meta,
        image: image_meta && image_meta.url,
      }}
      mainPage={mainPage}
      locale={locale}
      path={location && location.pathname}
      alternateLanguages={data.prismicTitleParagraphs.alternate_languages}
    >
      <RenderBody
        items={items}
        title={title}
        sides={sides}
        subtitle={subtitle}
        styles={styles && styles.map(s => s.style)}
      />
    </Layout>
  )
}

export default withPreview(TitleParagraphsTemplate)
